import PropTypes from "prop-types";

/* Helpers from StoryCard */
import get from "lodash.get";
import set from "lodash.set";
import merge from "lodash.merge";
import defaults from "~/components/features/fronts/flex-feature/utilities/custom-field-defaults";
import { getLink } from "~/shared-components/story-card/_utilities/helpers";
import {
  getAudio,
  groomAudioForJsonApp
} from "~/shared-components/story-card/_children/Audio.helpers.js";
import {
  getCompoundLabel,
  groomCompoundLabelForJsonApp
} from "~/shared-components/story-card/_children/Label.helpers.js";
import { getCount } from "~/shared-components/story-card/_children/Count.helpers.js";
import {
  getCallToAction,
  groomCallToActionForJsonApp
} from "~/shared-components/story-card/_children/CallToAction.helpers.js";
import { getExcerpt } from "~/shared-components/story-card/_children/Excerpt.helpers.js";
import {
  getFootnote,
  groomFootnoteForJsonApp
} from "~/shared-components/story-card/_children/Footnote.helpers.js";
import {
  getHeadline,
  groomHeadlineForJsonApp
} from "~/shared-components/story-card/_children/Headline.helpers.js";
import {
  getMultiBlurb,
  groomMultiBlurbForJsonApp
} from "~/shared-components/story-card/_children/Blurb.helpers.js";
import { getRecipe } from "~/shared-components/story-card/_children/Recipe.helpers.js";
import {
  getRelatedLinks,
  groomRelatedLinksForJsonApp
} from "~/shared-components/story-card/_children/RelatedLinks.helpers.js";
import {
  getLiveTicker,
  groomLiveTickerForJsonApp
} from "~/shared-components/story-card/_children/LiveTicker.helpers.js";
import {
  getSigline,
  groomSiglineForJsonApp
} from "~/shared-components/story-card/_children/Sigline.helpers.js";
import {
  getSlideshow,
  groomSlideshowForJsonApp
} from "~/shared-components/story-card/_children/Slideshow.helpers.js";
import {
  getElection,
  groomElectionForJsonApp
} from "~/shared-components/story-card/_children/Election.helpers.js";
import {
  getOlympics,
  groomOlympicsForJsonApp
} from "~/shared-components/story-card/_children/Olympics.helpers.js";
import {
  getTopperLabel,
  groomTopperLabelForJsonApp
} from "~/shared-components/story-card/_children/TopperLabel.helpers.js";
// NOTE: Sadly, Video does not yet follow the established pattern
// of getComponent() i.e getVideo(). Some day!
import {
  getVideoData,
  getVideo
} from "~/shared-components/story-card/_children/Video.helpers.js";
import {
  getImage,
  groomImageForJsonApp
} from "~/shared-components/story-card/_children/Image.helpers.js";

// NOTE: If cta or footnote is present, the apps want the feature to declare itself
// feature/promo instead of feature/flex-feature. Sad but true.
export const adjustFeatureProps = ({ featureProps, index }) => {
  // NOTE: This adds the index to the id of feed items
  if (index !== undefined) {
    const newId = /(.*)-\d+$/.exec(featureProps.id)?.[1] || featureProps.id;
    featureProps.id = `${newId}-${index}`;
  }
  return featureProps;
};

export const getActions = ({ json, content }) => {
  if (!!json?.signature?.showActions && json?.link) {
    const audio = groomAudioForJsonApp({
      audio: getAudio({
        content,
        overrides: {
          ...defaults,
          audioAllowArticleType: "any"
        }
      })
    });
    if (audio) {
      if (/article/.test(audio.audioType)) {
        delete audio.audioType;
        return { audio_article: audio };
      }
      delete audio.audioType;
      return { audio };
    }
    return {};
  }
  return undefined;
};

/* Utilities */
export const getJsonFromLayout = ({
  outputType,
  layout,
  content,
  artSlot,
  podcastData,
  liveTickerData,
  videoData,
  imageData,
  electionData,
  olympicsData,
  overrides,
  index,
  metaValue
}) => {
  const components = {
    Art: () => {
      let type = "image";
      // NOTE: For when backing content is inline video and there is no alt art
      videoData = getVideoData({ content, videoData, overrides });
      type = overrides.inlineVideo && !!videoData ? "video" : type;
      type = !overrides.liveGraphicsHide && electionData ? "election" : type;
      type = !overrides.liveGraphicsHide && olympicsData ? "olympics" : type;
      if (type === "olympics") {
        const olympics = getOlympics({
          olympicsData,
          overrides
        });
        if (olympics) {
          return groomOlympicsForJsonApp({ olympics });
        }
        return undefined;
      }
      if (type === "election") {
        const election = getElection({
          electionData,
          overrides
        });
        if (election) {
          return groomElectionForJsonApp({ election });
        }
      }

      let video;
      let image;
      if (type === "video") {
        ({ media: video } = getVideo({
          content,
          artSlot,
          videoData,
          imageData,
          overrides,
          metaValue
        }));
      }
      if (type === "image") {
        image = getImage({ content, imageData, artSlot, overrides });
        image = groomImageForJsonApp({ image });
      }

      // NOTE: Add slideshow as dynamicReplacement
      const slideshow = groomSlideshowForJsonApp({
        slideshow: getSlideshow({ content, artSlot, overrides })
      });
      // If another kind of dynamic replacement item comes along,
      // make sure to add only one.
      const dynamicReplacement = slideshow;

      if (video || image || dynamicReplacement) {
        return {
          media: {
            ...(dynamicReplacement || {}),
            ...(image || {}),
            ...(video || {})
          }
        };
      }

      return undefined;
    },

    Audio: () => {
      let audio = getAudio({
        content,
        podcastData,
        overrides
      });
      audio = groomAudioForJsonApp({ audio });
      if (audio) {
        if (/article/.test(audio.audioType)) {
          delete audio.audioType;
          return { audio_article: audio };
        }
        delete audio.audioType;
        return { audio };
      }
      return undefined;
      /*
      return
        { audio OR audio_article: { ... } }
      */
    },

    Blurb: () => {
      let blurbs = getMultiBlurb({ content, overrides });

      if (blurbs) {
        blurbs = groomMultiBlurbForJsonApp({ blurbs });
        return { blurbs };
      }
      return undefined;
    },

    Count: () => {
      const count = getCount({ content, overrides });

      if (count) {
        return { count };
      }
      return undefined;
    },

    CallToAction: () => {
      let cta = getCallToAction({ content, overrides });

      if (cta) {
        cta = groomCallToActionForJsonApp({ ...cta });
        return { cta };
      }
      return undefined;
    },

    Headline: () => {
      const headline = getHeadline({
        content,
        overrides,
        isAdmin: false
      });

      if (headline) {
        return { headline: groomHeadlineForJsonApp({ headline }) };
      }
      return undefined;
    },

    Excerpt: () => {
      const excerpt = getExcerpt({ content, overrides });

      if (excerpt) {
        return { excerpt };
      }
      return undefined;
    },

    Footnote: () => {
      let footnote = getFootnote({ content, overrides });

      if (footnote) {
        footnote = groomFootnoteForJsonApp({ footnote });
        return { footnote };
      }
      return undefined;
    },

    Image: () => getImage({ content, artSlot, overrides }),

    Label: () => {
      let compoundLabel = getCompoundLabel({
        content,
        overrides,
        outputType,
        isAdmin: false
      });
      if (get(compoundLabel, "show", false)) {
        compoundLabel = groomCompoundLabelForJsonApp({ compoundLabel });
        return {
          label: compoundLabel
        };
      }
      return undefined;
      /* NOTE:
        1) groomCompoundLabelForJsonApp() massages the underlying compoundLabel
          removes some data like details and show flag
          kebab cases enum values (not keys) like the position, icon
        2) overall snake casing of keys happens upstream of this code in output-type/jsonapp
      */

      /* NOTE: END GOAL IS
      return {
        type: "string:cta|package|package-nested|pill|exclusive-pill|live-updates|kicker",
        position: "string:default|above-headline",
        aligment: "string:inherit|left|center",
        icon: "string:none|camera|chart|headphones|election-star",
        showArrow: boolean,
        label: {
          text: "string"
        },
        labelSecondary: {
          text: "string"
        },
        link: {
          url: "string",
          type: "unknown" // ideally, this would be known :)
        }
      };
      */
    },

    LiveTicker: () => {
      let liveTicker = getLiveTicker({ content, liveTickerData, overrides });
      if (liveTicker) {
        liveTicker = groomLiveTickerForJsonApp({ liveTicker });
        return { live_ticker: liveTicker };
      }
      return undefined;
      /* NOTE: END GOAL
      {
        live_ticker: {
           label,
           url: "string:CANONICAL_URL",
           numToShow: int
        }
      };
      */
    },

    Recipe: () => {
      const recipe = getRecipe({ content, overrides });
      if (recipe) {
        return { recipe_info: recipe };
      }
      return undefined;
    },

    RelatedLinks: () => {
      const rls = groomRelatedLinksForJsonApp({
        relatedLinks: getRelatedLinks({ content, overrides })
      });
      if (get(rls, "items[0].text")) {
        return { related_links: rls };
      }
      return undefined;
      /* NOTE: END GOAL IS
      {
        related_links: {
          items: [
            {
              text: "string",
              type: "string:article|video|gallery|graphic|etc.|web|unknown", // for now, we're at unknown
              url: "string"
            }
          ],
          info: {
            position: "string:below-sigline|bottom",
            arrangement: "string:stacked|side-by-side",
            alignment: "string:left"
          }
        }
      };
      */
    },

    Sigline: () => {
      const sigline = getSigline({
        outputType,
        content,
        overrides
      });
      const signature = groomSiglineForJsonApp({ sigline });
      if (signature) {
        return { signature };
      }
      return undefined;
      /*  END GOAL IS
      signature: {
        by_line: STRING
        timestamp: DATE_STRING_IN_ISO_FORMAT
        recencyThreshold: INT (in minutes)
      }
      */
    },

    TopperLabel: () => {
      let topperLabel = getTopperLabel({
        overrides,
        index,
        isAdmin: false
      });
      if (get(topperLabel, "label.show", false)) {
        topperLabel = groomTopperLabelForJsonApp({ topperLabel });
        return {
          // NOTE: To exclude topper label from arrangements
          // topperLabel: topperLabel
          // NOTE: To include topper label in arrangements
          topper_label: topperLabel
        };
      }
      return undefined;
    }
  };

  const initJson = () => {
    const getSource = () => {
      return get(
        content,
        "owner.name",
        get(content, "owner.source", get(content, "source.name"))
      );
    };

    const getCommercialNode = () => {
      return get(content, "tracking.commercial_node");
    };

    const getLinkObj = () => {
      return getLink({ content, overrides });
    };

    // NOTE: Harvests any custom fields starting with "cardify" and puts them
    // into a cardInfo object. This is so that they can be easily ignored by
    // web and also easily deleted from the the jsonapp feed.
    const getCardifyInfo = () => {
      const re = /^cardify/;
      return Object.keys(overrides)
        .filter((key) => re.test(key))
        .reduce((acc, key) => {
          const newKey = key.replace(re, "");
          acc[newKey] = overrides[key];
          return acc;
        }, {});
    };

    // TODO: Figure out what else needs to go in here
    const { link, offlineLink } = getLinkObj();
    return {
      content_id: content?._id,
      commercial_node: getCommercialNode(),
      link,
      offline_link: offlineLink,
      source: getSource(),
      cardInfo: getCardifyInfo()
    };
  };

  // TOP-LEVEL
  const json = initJson();

  Object.keys(layout).forEach((position) => {
    layout[position].items.forEach((key) => {
      if (components[key]) {
        merge(json, components[key]({ content, overrides, layout }));
      }
    });
  });

  merge(json, { actions: getActions({ json, content }) });

  // START: Handle dynamicReplacement
  Object.keys(json).forEach((key) => {
    const node = json[key];
    const replacementKey = get(node, "dynamicReplacement");
    if (replacementKey) {
      const replacement = get(node, replacementKey);
      if (replacement) {
        delete node[replacementKey];
        set(json, replacementKey, replacement);
      }
    }
  });

  return {
    ...json
  };
};

getJsonFromLayout.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  overrides: PropTypes.object
};
