import { Component } from "react";
import PropTypes from "prop-types";
import Consumer from "fusion-consumer";
import clonedeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import merge from "lodash.merge";
import {
  getRenderedContentCache,
  cacheRenderedContentItem
} from "~/components/contexts/rendered-content-context.helpers";
import { checkIsSuppressibleDuplicate } from "~/components/utilities/handle-duplicate-content";
import { adjustFeatureProps, getJsonFromLayout } from "./jsonapp.helpers.js";
import { getAncillaryContentConfig, getFeedLimit } from "./default.helpers.js";
import fetchLayoutFromArtPosition from "~/shared-components/story-card/_children/artPosition";
import {
  applyMobilePresets,
  isMobilePresetAsIs
} from "./utilities/mobile-presets";
import {
  feedType,
  hasCustomContentTantamountToContent,
  adjustOverrides,
  getArtSlotInfo
} from "./utilities/index";
import { showChainOnDesktopOrMobile } from "~/shared-components/chains/top-table/utilities/data-transformation";
import {
  addComponentFlags,
  allTransformsOrder,
  getSubComponentArrangements,
  isFeatureHiddenOnDesktop,
  isFeatureHiddenOnMobile
} from "~/shared-components/story-card/_utilities/helpers";
import { updateFetchReportAll } from "~/components/output-types/jsonapp.health";
import { filterWebViews } from "./jsonapp-lite.helpers";

const getFeatureProps = ({ props, customFields }) => {
  const { artPosition = "" } = customFields;
  return {
    id: props.id,
    item_type: "fronts/flex-feature",
    ...(/fronts\/flex-feature/.test(props.type)
      ? {}
      : { item_type_impl: props.type }),
    displayName: customFields.displayName,
    wrap_text: artPosition.match(/right/i) ? !!customFields.wrapText : false,
    text_alignment: customFields.textAlignment,
    lastPickedPreset: customFields.lastPickedPreset
  };
};

const getVitalFeatureData = ({ props, customFields, doMobilePreset }) => {
  const { outputType } = props;

  let overrides;
  let featureProps;
  let layout;

  if (
    !doMobilePreset ||
    (doMobilePreset && !isMobilePresetAsIs(customFields))
  ) {
    overrides = clonedeep(customFields);
    if (doMobilePreset) {
      overrides = applyMobilePresets({
        customFields: overrides,
        chainCtx: { useDesktopOrdering: false }
      });
    }

    // NOTE: For each component add a flag like Headline: true
    overrides = addComponentFlags(overrides);
    featureProps = getFeatureProps({ props, customFields: overrides });
    ({ layout } = fetchLayoutFromArtPosition(overrides));
    // Critical piece -- this modifies the layout so the components are ordered the way we want.
    layout = allTransformsOrder({ layout, overrides });

    // Adjust overrides for certain special cases
    overrides = adjustOverrides(overrides, outputType);

    return {
      featureProps,
      overrides,
      layout
    };
  }
  return {};
};

const getArtSlotData = ({ content, overrides }) => ({
  artSlot: getArtSlotInfo({
    content,
    overrides
  })
});

/*  IF
 *   * The only key (with a non-boolean falsey value)
 *      in a node is dynamicReplacement and
 *   * The 'node' is in arrangements
 *  THEN
 *   * Replace 'node' with the value of dynamicReplacement in arrangements
 *   * Remove node from the json.
 *
 *  NOTE: See jsonapp.test.js for test cases that could make it clearer
 *  what's going on
 */
export const adjustDynamicReplacements = ({ json = {}, arrangements = {} }) => {
  Object.keys(json).forEach((target) => {
    const node = json[target];
    if (typeof node === "object" && node !== null) {
      // NOTE: Filter out keys with non-boolean falsey values
      const keys = Object.keys(node).reduce((acc, k) => {
        if (typeof node[k] === "boolean" || !!node[k]) acc.push(k);
        return acc;
      }, []);
      // NOTE: dynamicReplacement is the only key
      if (keys.length === 1 && keys.includes("dynamicReplacement")) {
        const dynamicReplacement = node.dynamicReplacement;
        if (json.hasOwnProperty(dynamicReplacement)) {
          Object.keys(arrangements).forEach((bp) => {
            // NOTE: arrangements has the key 'default' at the top level
            // with the idea that there could be other arrangements at other
            // breakpoints. This has never been activated.
            Object.keys(arrangements[bp]).forEach((pos) => {
              // NOTE: These should be arrangements.default.(top|left|etc)
              const items = get(arrangements[bp][pos], "items", []);
              if (items.length > 0) {
                set(
                  arrangements[bp][pos],
                  "items",
                  items.map((v) => (v === target ? dynamicReplacement : v))
                );
                delete json[target];
              }
            });
          });
        }
      }
    }
  });
  return {
    json,
    arrangements
  };
};

// NOTE: mobilePreset can have art when the desktop preset does not.
// Lucky on two counts:
//   1. For non-feeds, the jsonapp code *always* fetches art
//   2. For feeds the code *never* fetches art
// ...so in both cases this code works without explicitly having to code for it.
// When the underlying code becomes more intelligent, this code may need to adapt, too
export const getVitalItemData = ({
  layout,
  content,
  ancillaryContent = {},
  artSlot,
  overrides,
  outputType,
  index,
  metaValue
}) => {
  let json = getJsonFromLayout({
    outputType,
    layout,
    content,
    ...ancillaryContent,
    artSlot,
    overrides,
    index,
    metaValue
  });

  let arrangements = getSubComponentArrangements({
    layout,
    overrides,
    json,
    outputType
  });

  ({ json, arrangements } = adjustDynamicReplacements({ json, arrangements }));

  return {
    json,
    arrangements
  };
};

const getLinkDetail = (content) => {
  // NOTE: Enhance as necessary. This doesn't *have* to be count
  const detail = get(content, "fusion_additions.count_info.count", undefined);
  return detail ? `${detail}` : undefined;
};

@Consumer
class FlexFeature extends Component {
  constructor(props) {
    super(props);

    const {
      id,
      rootCustomFields,
      customFields,
      parent,
      outputType,
      metaValue,
      curationIndices,
      cache
    } = props;

    const renderedContent = getRenderedContentCache(cache);

    const chainOverrides = get(parent, "props.customFields", {});

    // START: generate vital data
    const { featureProps, overrides, layout } = getVitalFeatureData({
      props,
      customFields
    });
    // END: generate vital data

    // START: generate vital data from mobile
    const {
      featureProps: featurePropsMobile,
      overrides: overridesMobile,
      layout: layoutMobile
    } = getVitalFeatureData({ props, customFields, doMobilePreset: true });
    // END: generate vital data from mobile

    const { flexFeatureContentConfig, mobilePreset } = overrides;

    const {
      type,
      contentConfig: thisContentConfig,
      clientSideConfig
    } = feedType({
      flexFeatureContentConfig,
      outputType,
      overrides
    });
    const isClientSideContent = !!clientSideConfig;

    // NOTE: See getAncillaryContentConfig for how the three args
    // overrides, overridesMobile, and outputType are being used
    // and why it's necessary for ouputType=jsonapp but not outputType=default
    const ancillaryContentConfig = getAncillaryContentConfig({
      overrides,
      overridesMobile,
      outputType
    });

    this.state = {
      type,
      content: undefined,
      listOfContent: []
    };

    // enhance with ancillary content
    Object.keys(ancillaryContentConfig).forEach((key) => {
      this.state[key] = undefined;
    });

    // NOTE: This does not take into account duplicates cuz that data needs to be
    // assembled which happens further down
    const showOnApps =
      showChainOnDesktopOrMobile(chainOverrides, outputType) &&
      (!isFeatureHiddenOnDesktop(overrides, outputType) ||
        !isFeatureHiddenOnMobile(overrides, outputType)) && // showOnDesktopOrMobile
      !isClientSideContent;

    if (showOnApps && /^(cards|no-backing-content)$/.test(type)) {
      // TODO: Consider stop supporting comma-separated list of URLs
      this.state.listOfContent = [thisContentConfig].map((config) => {
        const contentConfig = {};

        // START: Add backing or empty content
        if (config && config.contentService) {
          contentConfig.content = config;
        } else {
          // NOTE: noContent config always exists
          contentConfig.content = ancillaryContentConfig.noContent;
        }
        // NOTE: noContent config has served its purpose, so delete
        delete ancillaryContentConfig.noContent;
        // END: Add backing or empty content

        // NOTE: Enhance content config with ancillary content
        // like videoData, podcastData, etc.
        Object.keys(ancillaryContentConfig).forEach((key) => {
          contentConfig[key] = ancillaryContentConfig[key];
        });

        // START: The holy grail!
        this.fetchContent(contentConfig);
        updateFetchReportAll(cache, parent, id, this.state, contentConfig);

        const thereIsBackingContent =
          !!this.state.content && !!this.state.content.type;
        // NOTE: Basically, Object.keys(localEdits).length > 0 means there has been a content edit.
        const thereIsCustomContentTantamountToContent =
          thereIsBackingContent ||
          hasCustomContentTantamountToContent(overrides);

        /* NOTE: B/c localEdits can only exist if there is content, the false
         * part of the ternary below doesn't ever do anything. Hence, the
         * simplified code below replaces this code.
        const buildContent = () => {
          // If there's no backing content, still merge in the local edits.
          // They have to be fetched a little differently.
          // TODO does this affect feeds? It shouldn't.
          const localEditsToMerge = Object.values(localEdits).map(
            (value) => value
          );
          return this.state.content
            ? merge({}, this.state.content, localEdits[this.state.content._id])
            : merge({}, ...localEditsToMerge);
        };
        const content = buildContent();
        */

        // NOTE: Merge localEdits into content
        // Additional NOTE: Assembler doen't have localEdits the same way as old fusion did.
        // Instead we just have customfields so we merge them here.
        const headlineMixin = customFields.headline
          ? { headlines: { basic: customFields.headline } }
          : {};
        const content = this.state.content
          ? merge({}, this.state.content, headlineMixin)
          : {};

        if (!(thereIsBackingContent || thereIsCustomContentTantamountToContent))
          return null;

        // START: check is duplicate
        const duplicateInfo = checkIsSuppressibleDuplicate({
          outputType,
          renderedContent,
          rootCustomFields,
          chainOverrides,
          content,
          curationIndices,
          // featureId: props.id,
          overrides
        });
        // END: check is duplicate

        // NOTE: If due to show on desktop AND/OR mobile, this will be true.
        const { showOnDesktopOrMobile } = duplicateInfo;

        if (!showOnDesktopOrMobile) return null;

        // START: register rendered content for deduping
        cacheRenderedContentItem({
          props,
          item: this.state.content,
          curationIndices,
          customFields,
          parent,
          renderedContent,
          outputType
        });
        // END: register rendered content for deduping

        // NOTE: Get this into overrides so it can be used downstream, like in <Sigline />
        overrides.thereIsBackingContent = thereIsBackingContent;
        overrides.thereIsCustomContentTantamountToContent =
          thereIsCustomContentTantamountToContent;
        if (overridesMobile) {
          overridesMobile.thereIsBackingContent = thereIsBackingContent;
          overridesMobile.thereIsCustomContentTantamountToContent =
            thereIsCustomContentTantamountToContent;
        }

        const ancillaryContent = Object.keys(ancillaryContentConfig).reduce(
          (acc, key) => {
            if (this.state[key]) {
              acc[key] = this.state[key];
            }
            return acc;
          },
          {}
        );

        // START: for non-mobile
        const { artSlot } = getArtSlotData({ content, overrides });
        const { json, arrangements } = getVitalItemData({
          layout,
          content,
          ancillaryContent,
          artSlot,
          overrides,
          outputType,
          index: 0,
          metaValue
        });
        // END: for non-mobile

        // START: for mobile
        const { artSlot: artSlotMobile } =
          overridesMobile && layoutMobile
            ? ((cb) =>
                cb({
                  content,
                  overrides: overridesMobile
                }))(getArtSlotData)
            : {};
        const { json: jsonMobile, arrangements: arrangementsMobile } =
          overridesMobile && layoutMobile
            ? ((cb) =>
                cb({
                  layout: layoutMobile,
                  content,
                  ancillaryContent,
                  artSlot: artSlotMobile,
                  overrides: overridesMobile,
                  outputType,
                  index: 0,
                  metaValue
                }))(getVitalItemData)
            : {};
        // END: for mobile

        const { showOnDesktop, showOnMobile } = duplicateInfo;
        const hasMobilePreset = !!(
          featurePropsMobile &&
          jsonMobile &&
          arrangementsMobile
        );

        return hasMobilePreset && showOnMobile
          ? {
              // NOTE: This gets flattened in top-table/jsonapp.js
              breakpointShapes: (() => {
                const shapes = [];
                if (showOnDesktop) {
                  shapes.push({
                    useDesktopOrdering: true,
                    isFromFeed: false,
                    ...adjustFeatureProps({ featureProps, arrangements }),
                    linkDetail: getLinkDetail(content),
                    arrangements,
                    duplicateInfo,
                    ...json
                  });
                }
                shapes.push({
                  useDesktopOrdering: false,
                  isFromFeed: false,
                  ...adjustFeatureProps({
                    featureProps: featurePropsMobile,
                    arrangements
                  }),
                  linkDetail: getLinkDetail(content),
                  mobilePreset,
                  arrangements: arrangementsMobile,
                  duplicateInfo,
                  ...jsonMobile
                });
                return shapes;
              })()
            }
          : (() => {
              if (!showOnDesktop || !showOnMobile) {
                return {
                  // NOTE: This gets flattened in top-table/jsonapp.js
                  breakpointShapes: (() => {
                    const shapes = [];
                    shapes.push({
                      useDesktopOrdering: showOnDesktop,
                      ...adjustFeatureProps({ featureProps }),
                      isFromFeed: false,
                      linkDetail: getLinkDetail(content),
                      arrangements,
                      duplicateInfo,
                      ...json
                    });
                    return shapes;
                  })()
                };
              }
              return {
                isFromFeed: false,
                ...adjustFeatureProps({ featureProps }),
                linkDetail: getLinkDetail(content),
                arrangements,
                duplicateInfo,
                ...json
              };
            })();
      });

      // START: Remove invalid items from listOfContent
      // Necessary b/c no-backing-content might not meet threshold of legit content
      for (let i = 0; i < this.state.listOfContent.length; i += 1) {
        if (
          Array.isArray(this.state.listOfContent) &&
          !this.state.listOfContent[i]
        ) {
          this.state.listOfContent.splice(i, 1);
          i -= 1;
        }
      }
    } else if (showOnApps && /^(feed)$/.test(type)) {
      const contentConfig = {};
      contentConfig.content = thisContentConfig;

      // NOTE: Enhance content config with ancillary content
      // like videoData, podcastData, etc.
      Object.keys(ancillaryContentConfig).forEach((key) => {
        contentConfig[key] = ancillaryContentConfig[key];
      });

      // TODO: Fetch art and other ancillary content
      this.fetchContent(contentConfig);
      updateFetchReportAll(cache, parent, id, this.state, contentConfig);

      // NOTE: This is to assure these values are numbers with proper defaults
      let offset = get(overrides, "offset", 0);
      offset = !Number.isNaN(Number.parseInt(offset, 10))
        ? Number.parseInt(offset, 10)
        : 0;
      // TODO: determine if useOffset: false should be used
      const limit = getFeedLimit({
        items: get(this, "state.content.items", []),
        overrides
      });

      if (limit === 0) {
        this.state.listOfContent = [];
      } else {
        const ancillaryContent = Object.keys(ancillaryContentConfig).reduce(
          (acc, key) => {
            if (this.state[key]) {
              acc[key] = this.state[key];
            }
            return acc;
          },
          {}
        );

        const items = this.state.content.items
          .slice(offset)
          // START: check is duplicate
          .filter((content) => {
            const duplicateInfo = checkIsSuppressibleDuplicate({
              outputType,
              renderedContent,
              rootCustomFields,
              chainOverrides,
              curationIndices,
              // featureId: props.id,
              content,
              overrides
            });

            // NOTE: If due to show on desktop AND/OR mobile, this will be true.
            const { showOnDesktopOrMobile } = duplicateInfo;

            // NOTE: Get this into content so it can be used downstream, e.g. for mobile/desktop deduping
            set(content, "additional_properties.duplicateInfo", duplicateInfo);

            return showOnDesktopOrMobile;
          });
        // END: check is duplicate

        // START: split into desktop and mobile lists
        let { desktopItems, mobileItems } = items.reduce(
          (acc, item) => {
            const { showOnDesktop, showOnMobile } = get(
              item,
              "additional_properties.duplicateInfo"
            );
            if (showOnDesktop) acc.desktopItems.push(item);
            if (showOnMobile) acc.mobileItems.push(item);
            return acc;
          },
          { desktopItems: [], mobileItems: [] }
        );

        desktopItems = desktopItems.slice(0, limit);
        mobileItems = mobileItems.slice(0, limit);
        // START: split into desktop and mobile lists

        const desktopIds = desktopItems.map(({ _id }) => _id);
        const mobileIds = mobileItems.map(({ _id }) => _id);

        this.state.listOfContent = items
          // NOTE: Only include items in the desktop or mobile list
          .filter(
            ({ _id }) => desktopIds.includes(_id) || mobileIds.includes(_id)
          )
          // START: register rendered content for deduping
          .map((item) => {
            cacheRenderedContentItem({
              props,
              item,
              curationIndices,
              customFields,
              parent,
              renderedContent,
              outputType
            });
            return item;
          })
          // END: register rendered content for deduping
          .map((item, i) => {
            // START: for non-mobile
            const { artSlot } = getArtSlotData({ content: item, overrides });
            const { json, arrangements } = getVitalItemData({
              layout,
              content: item,
              ancillaryContent,
              artSlot,
              overrides,
              outputType,
              index: i,
              metaValue
            });
            // END: for non-mobile

            // START: for mobile
            const { artSlot: artSlotMobile } =
              overridesMobile && layoutMobile
                ? ((cb) =>
                    cb({
                      content: item,
                      overrides: overridesMobile
                    }))(getArtSlotData)
                : {};
            const { json: jsonMobile, arrangements: arrangementsMobile } =
              overridesMobile && layoutMobile
                ? ((cb) =>
                    cb({
                      layout: layoutMobile,
                      content: item,
                      ancillaryContent,
                      artSlot: artSlotMobile,
                      overrides: overridesMobile,
                      outputType,
                      index: i,
                      metaValue
                    }))(getVitalItemData)
                : {};
            // END: for mobile

            const duplicateInfo = get(
              item,
              "additional_properties.duplicateInfo"
            );
            const { showOnDesktop, showOnMobile } = duplicateInfo;

            const hasMobilePreset = !!(
              featurePropsMobile &&
              jsonMobile &&
              arrangementsMobile
            );

            // NOTE: If an item is not due to show at desktop OR mobile, it has already been excluded
            // by this point in the code. Also, the true condition
            return hasMobilePreset && showOnMobile
              ? {
                  // NOTE: This gets flattened in top-table/jsonapp.js
                  breakpointShapes: (() => {
                    const shapes = [];
                    if (showOnDesktop) {
                      shapes.push({
                        useDesktopOrdering: true,
                        ...adjustFeatureProps({
                          featureProps,
                          index: i + offset
                        }),
                        isFromFeed: true,
                        index: i,
                        linkDetail: getLinkDetail(item),
                        arrangements,
                        duplicateInfo,
                        ...json
                      });
                    }
                    shapes.push({
                      useDesktopOrdering: false,
                      isFromFeed: true,
                      index: i,
                      linkDetail: getLinkDetail(item),
                      ...adjustFeatureProps({
                        featureProps: featurePropsMobile,
                        index: i + offset
                      }),
                      mobilePreset,
                      arrangements: arrangementsMobile,
                      duplicateInfo,
                      ...jsonMobile
                    });
                    return shapes;
                  })()
                }
              : (() => {
                  if (!showOnDesktop || !showOnMobile) {
                    return {
                      // NOTE: This gets flattened in top-table/jsonapp.js
                      breakpointShapes: (() => {
                        const shapes = [];
                        shapes.push({
                          useDesktopOrdering: showOnDesktop,
                          ...adjustFeatureProps({
                            featureProps,
                            index: i + offset
                          }),
                          isFromFeed: true,
                          index: i,
                          linkDetail: getLinkDetail(item),
                          arrangements,
                          duplicateInfo,
                          ...json
                        });
                        return shapes;
                      })()
                    };
                  }
                  return {
                    isFromFeed: true,
                    index: i,
                    linkDetail: getLinkDetail(item),
                    ...adjustFeatureProps({
                      featureProps,
                      index: i + offset
                    }),
                    arrangements,
                    duplicateInfo,
                    ...json
                  };
                })();
          });
      }
    }
  }

  render() {
    if (!this.state.listOfContent) return null;

    const listOfContent =
      this.props.outputType === "jsonapp-lite"
        ? this.state.listOfContent.filter(filterWebViews)
        : this.state.listOfContent;

    if (!this.state.listOfContent.length) return null;

    if (this.state.type === "feed") {
      return {
        // NOTE: This get flattened in top-table/jsonapp.js
        feed: listOfContent.map(function listContentMap(item) {
          return {
            ...item
          };
        })
      };
    }

    const val = {
      ...listOfContent[0]
    };
    return val;
  }
}

FlexFeature.propTypes = {
  id: PropTypes.string,
  outputType: PropTypes.string,
  metaValue: PropTypes.func,
  parent: PropTypes.object,
  rootCustomFields: PropTypes.object,
  customFields: PropTypes.object,
  curationIndices: PropTypes.object,
  cache: PropTypes.object
};

export default FlexFeature;
